import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout.jsx";
import SEO from "../components/seo.jsx";
import styles from "./../styles/modules/project.module.scss";

const BlogArticle = ({ data }) => {
  const article = data.markdownRemark.frontmatter;
  const articleHtml = { __html: data.markdownRemark.html };

  return (
    <Layout header="none">
      <SEO
        title="Work"
        keywords={[
          `web`,
          `developer`,
          "frontend",
          "front end",
          "front-end",
          "ux",
          "designer",
          "javascript developer",
          "react"
        ]}
      />
      <main className={styles.main}>
        <div className={styles.description}>
          <h1>{article.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: article.description }} />
          <div className={styles.tags}>
            <div className={styles.label}>Technologies:</div>
            <div className={styles.descrip}>
              <b>{article.technologies}</b>
            </div>
            <div className={styles.label}>Year:</div>
            <div className={styles.descrip}>
              <b>{article.year}</b>
            </div>
            <div className={styles.label}>Role:</div>
            <div className={styles.descrip}>
              <b>{article.role}</b>
            </div>
            {article.link ? (
              <>
                <div className={styles.label}>Link:</div>
                <div className={styles.descrip}>
                  <b>
                    <a
                      href={article.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {article.link}
                    </a>
                  </b>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={styles.content} dangerouslySetInnerHTML={articleHtml} />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        description
        technologies
        year
        role
        link
      }
    }
  }
`;

export default BlogArticle;
